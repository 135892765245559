.create-modal-content {
  .tab-nav {
    position: relative;
    .tabs-tab {
      display: inline-block;
      padding-bottom: 12px;
      margin-right: 20px;
      cursor: pointer;
      color: rgba(0, 0, 0, 0.32);
      text-transform: uppercase;
      &.selected {
        color: #000;
      }
    }
    .tabs-ink-bar {
      position: absolute;
      bottom: 1px;
      left: 0;
      z-index: 1;
      box-sizing: border-box;
      width: 60px;
      height: 2px;
      background-color: #000;
      transform-origin: 0 0;
      display: block;
      transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }
  .tab-pane {
    padding-top: 20px;
    padding-bottom: 20px;
    &.secret {
      .tab-pane-btn {
        margin-top: 92px;
      }
    }
    &-t {
      font-size: 20px;
      color: #000;
      margin-bottom: 10px;
      font-weight: 500;
    }
    &-d {
      color: #000;
      opacity: 0.44;
      font-size: 14px;
      margin-bottom: 15px;
    }
    &-input {
      width: 100%;
      border: 1px solid rgba(220, 220, 220, 1);
      border-radius: 12px;
      padding: 12px 15px;
      font-size: 18px;
      &:focus {
        outline: none;
      }
      &::-webkit-input-placeholder {
        color: rgba(0, 0, 0, 0.3);
      }
    }
    &-btn {
      width: 80%;
      margin: 0 auto;
      margin-top: 30px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: rgba(243, 166, 38, 1);
      border-radius: 20px;
      color: #fff;
      cursor: pointer;
      font-weight: bold;
      user-select: none;
    }
  }
}
