.enter-secret-modal {
  &-t {
    font-size: 20px;
    color: #000;
    margin-bottom: 20px;
    font-weight: 500;
  }
  &-d {
    color: #000;
    opacity: 0.44;
    font-size: 14px;
    margin-bottom: 25px;
  }
  &-action {
    display: flex;
    justify-content: flex-end;
    .modal-btn {
      padding: 8px;
      cursor: pointer;
      font-size: 16px;
      &.cancel {
        margin-right: 10px;
      }
    }
  }
}
