.top-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  z-index: 10;
  padding: 6px 12px;
  border-bottom: 1px solid #A7A7A7;
  &-left {
    width: 38px;
    height: 38px;
    display: block;
    margin-right: 10px;
    flex-shrink: 0;
  }
  &-middle {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &-t {
      font-size: 14px;
      color: #000000;
    }
    &-d {
      font-size: 12px;
      color: rgba(0,0,0,0.50);
    }
  }
  &-right {
    width: 50px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    background: #363636;
    color: #fff;
    border-radius: 30px;
    font-size: 13px;
    margin-left: 10px;
    flex-shrink: 0;
  }
}