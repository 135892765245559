@font-face {
  font-family: 'BlackOpsOne';
  src: url('./font/BlackOpsOne-Regular.ttf');
}

* {
  box-sizing: border-box;
}

html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  color: inherit;
  margin: 0;
  padding: 0;
}

body {
  background: #ffd52e;
}

// html,
// body,
// #root,
// .app {
//   min-height: 100%;
// }

.splash-screen {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #3e3e3e;
  z-index: 500;
  .icon-logo {
    margin-top: 50vh;
    transform: translateY(-50%);
    text-align: center;
    img {
      width: 100px;
      height: 100px;
    }
  }
}

.fadeOut {
  transition: opacity 0.25s ease-in-out;
  opacity: 0;
  z-index: -1;
}

.app {
  height: 200px;
  .store {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    margin-bottom: 20px;
    a {
      width: 48%;
      img {
        width: 100%;
      }
    }
  }
  .container {
    &-talkie {
      width: 313px;
      margin: auto;
      position: relative;
      margin-top: 50vh;
      transform: translateY(-50%);
    }
    &-main {
      position: relative;
      &.connected {
        &.private {
          .channel-list,
          .container-screen-bg {
            background-image: url('./images/screen-private.png');
          }
        }
      }
      &.disconnected {
        .container-screen {
          .screen-status,
          .screen-user {
            opacity: 0.6;
            color: #ffffff;
          }
          .screen-user {
          }
          .screen-bottomleft {
            .private-icon {
              background-image: url('./images/private-white.png');
            }
          }
          .screen-bottomleft,
          .screen-channel {
            opacity: 0.8;
            color: #ffffff;
            &::-webkit-input-placeholder {
              color: rgba(255, 255, 255, 0.8);
            }
          }
        }
        .container-screen-bg {
          background-image: url('./images/screen-off.png');
        }
        .channel-list {
          background-image: url('./images/screen-off.png');
          &-left {
            color: rgba(255, 255, 255, 0.6);
          }
          &-right {
            color: rgba(255, 255, 255, 0.8);
          }
          &-lefticon {
            background-image: url('./images/private-list-white.png');
            opacity: 0.7;
          }
        }
        &.private {
          .channel-list,
          .container-screen-bg {
            background-image: url('./images/screen-private-off.png');
          }
        }
      }

      .channel-list {
        // 控制整个滚动条
        &::-webkit-scrollbar {
          background-color: rgba(0, 0, 0, 0.2);
          width: 5px;
          height: 60%;
          background-clip: padding-box;
          display: none;
        }
        display: none;
        position: absolute;
        z-index: 10;
        bottom: 9px;
        left: 9px;
        width: 295px;
        height: 200px;
        border-bottom-left-radius: 44px;
        border-bottom-right-radius: 44px;
        // background-color: #8fd850;
        background-image: url('./images/screen.png');
        background-position: -10px;
        overflow-y: auto;
        padding-left: 14px;
        padding-right: 23px;
        &-item {
          height: 40px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          font-size: 20px;
          font-family: 'BlackOpsOne';
          cursor: pointer;
        }
        &-left {
          // img {
          //   display: block;
          //   width: 18px;
          //   height: 18px;
          //   position: relative;
          //   left: -1px;
          // }
        }
        &-lefticon {
          width: 17px;
          height: 17px;
          position: relative;
          left: -1px;
          background-image: url('./images/private-list.png');
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
    }
    &-screen {
      font-family: 'BlackOpsOne';
      padding: 8px 8px 0;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      background: #221f1f;
      border: 1px solid #725e0e;
      border-bottom: none;
      box-shadow: 0 1px 7px 0 rgba(96, 82, 28, 0.31),
        inset 1px 2px 2px 0 rgba(25, 25, 25, 0.28);
      margin-bottom: -1px;
      &-inner {
        width: 100%;
        height: 133px;
        position: relative;
      }
      &-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        text-transform: uppercase;
        width: 100%;
        &:nth-child(1) {
          padding-left: 12px;
          padding-right: 21px;
          position: absolute;
          top: 21px;
        }
        &:nth-child(2) {
          padding-left: 12px;
          padding-right: 23px;
          position: absolute;
          bottom: 19px;
        }
      }

      .screen-status {
        font-size: 16px;
        color: #333333;
        letter-spacing: 0;
        line-height: 25px;
      }
      .screen-user {
        font-size: 20px;
        color: #2c2c2c;
        letter-spacing: 0;
      }
      .screen-mic {
        display: inline-block;
        width: 23px;
        height: 20px;
        opacity: 0.2;
        background-image: url('./images/mic.png');
        background-repeat: no-repeat;
        background-size: cover;
        vertical-align: -2px;
        margin-right: 4px;
        &.mic-max {
          opacity: 1;
          background-image: url('./images/mic-mute.png');
        }
        &.speaking {
          opacity: 1;
        }
      }
      .screen-bottomleft {
        font-size: 26px;
        color: #333333;
        letter-spacing: 0;
        line-height: 1;
        display: flex;
        align-items: center;
        .private-icon {
          width: 20px;
          height: 20px;
          position: relative;
          top: -1px;
          background-image: url('./images/private.png');
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
      .screen-channel {
        width: 70%;
        text-align: right;
        font-family: 'BlackOpsOne';
        font-size: 24px;
        color: #2c2c2c;
        letter-spacing: 0;
        background-color: transparent;
        border: none;
        &:focus {
          outline: none;
        }
        &::-webkit-input-placeholder {
          color: rgba(44, 44, 44, 0.2);
        }
      }

      &-bg {
        width: 100%;
        height: 100%;
        background-image: url('./images/screen.png');
        background-repeat: no-repeat;
        background-size: cover;
        &.private {
          background-image: url('./images/screen-private.png');
        }
      }
    }
    &-btns {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 12px 0;
      padding: 12px 20px;
      border-bottom-left-radius: 44px;
      border-bottom-right-radius: 44px;
      background: #221f1f;
      border: 1px solid #725e0e;
      border-top: none;
      box-shadow: 0 1px 7px 0 rgba(96, 82, 28, 0.31),
        inset 1px 2px 2px 0 rgba(25, 25, 25, 0.28);
      &-left,
      &-center,
      &-right {
        // display: inline-block;
        width: calc(100% / 3 - 8px);
        height: 164px;
        margin: 4px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
      }
      .btn-switch-on,
      .btn-switch,
      .btn-up,
      .btn-down,
      .btn-sound,
      .btn-create-private {
        border-radius: 30px;
        border: 0 solid #3f424c;
        background-color: #363636;
        box-shadow: 0 4px 8px 0 #1c1a1a, inset 0 2px 1px 0 rgba(71, 71, 71, 0.5);
        height: calc(100% / 2 - 4px);
        width: 100%;
        background-repeat: no-repeat;
        background-size: 30px;
        background-position: 50%;
        cursor: pointer;
      }
      .btn-up {
        background-image: url('./images/up.png');
      }
      .btn-down {
        background-image: url('./images/down.png');
      }
      .btn-sound {
        &.disabled {
          cursor: not-allowed;
          .btn-sound-bg {
            opacity: 0.6;
          }
        }
        &-bg {
          width: 100%;
          height: 100%;
          background-image: url('./images/sound.png');
          background-repeat: no-repeat;
          background-size: 30px;
          background-position: 50%;
        }
      }
      .btn-create-private {
        // background-image: url('./images/create-private.png');
        background-image: url('./images/new-add.png');
      }

      .btn-switch {
        height: 100%;
        background-image: url('./images/switch-off.png');
      }
      .btn-switch-on {
        height: 100%;
        background-color: #f1f1f1;
        background-image: url('./images/switch-on.png');
        box-shadow: none;
      }
    }

    &-speak {
      position: relative;
      text-align: center;
      width: 232px;
      margin: 0 auto;
      margin-top: 50px;
      cursor: pointer;
      opacity: 0.8;
      &.disabled {
        cursor: not-allowed;
        .push-to-talk {
          visibility: hidden;
        }
      }
      .push-to-talk {
        font-family: 'BlackOpsOne';
        color: rgba(0, 0, 0, 0.5);
        font-size: 16px;
        text-transform: uppercase;
        height: 20px;
        line-height: 20px;
        width: 182px;
        background: #ffd52e;
        color: #673f02;
        letter-spacing: 0;
        position: absolute;
        top: 49%;
        left: 50%;
        transform: translate(-50%, -50%);
        visibility: visible;
        user-select: none;
      }
      img {
        width: 232px;
        height: 181px;
      }
    }
  }
}

#main {
  display: flex;
  justify-content: center;
  .ad-bar {
    flex: 1;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    &.left {
      margin-right: 20px;
    }
    &.right {
      margin-left: 20px;
    }
  }
}

.ReactModal__Overlay {
  background-color: rgba(0,0,0,0.54) !important;
}
.custom-modal {
  width: 325px;
  background-color: #fff;
  border-radius: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 16px 16px 8px;
  &:focus {
    outline: none;
  }
}

@media screen and (max-width: 767px) {
  .app {
    height: auto;
    .container-talkie {
      margin-top: 60px;
      transform: none;
    }
  }
  #main {
    display: block;
    .ad-bar {
      display: none;
    }
  }
}
